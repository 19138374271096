import { BenefitApplicationStatus, Status } from 'src/shared/types/swagger/api.dto'

export const getVariantByStatus = (status: Status | BenefitApplicationStatus) => {
  switch (status) {
    case Status.ACCEPTED:
    case Status.APPROVED_BY_MANAGER:
    case Status.PROCESSING_BY_HR:
    case BenefitApplicationStatus.IN_REVIEW:
      return 'success'

    case Status.DECLINED:
      return 'error'

    case Status.SENT_FOR_REVISION:
      return 'warning'

    default:
      return 'info'
  }
}
